import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import GetImage from "./GetImage";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import About from './About';

export const StyledImgNew = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 8px ridge var(--secondary);
  background-color: var(--accent);
  border-radius: 36px;
  width: 512px;
  @media (min-width: 900px) {
    width: 512px;
  }
  @media (min-width: 1000px) {
    width: 512px;
  }
  transition: width 0.5s;
`;

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButtonConnect = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--buttonConnect);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonBuy = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--buttonBuy);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--buttonBg);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 400px;
  @media (min-width: 767px) {
    width: 650px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 8px ridge var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const CoinImg = styled.img`
  box-shadow: 1px 1px 5px 5px rgba(200, 200, 200, 0.7);

  background-color: var(--accent);
  border-radius: 100%;
  width: 128px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 180px;
  }
  transition: width 0.5s;
`;



function Home() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [ownersNft, setOwnersNft] = useState(0);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
	STAGE_SUPPLY: 1,
	TEMP_TOTAL_SUPPLY: 0,
    WEI_COST: 0,
    DISPLAY_COST: 0,
	WALLET_LIMIT: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
	FACE_IMAGES: "",
	FACE_IMAGES2: "",
	FACE_IMAGES3: "",
	FACE_IMAGES4: "",
	FACE_IMAGES5: "",
  });

	let myUrl = CONFIG.FACE_IMAGES;
	let myUrl2 = CONFIG.FACE_IMAGES2
	let myUrl3 = CONFIG.FACE_IMAGES3
	let myUrl4 = CONFIG.FACE_IMAGES4
	let myUrl5 = CONFIG.FACE_IMAGES5
	let myTail = ".png";
	let theVisibility = 'hidden';
	let buyButtonVisibility = 'visible';
	if	(data.ownerTokenCount.length > 0 )	{
		theVisibility = 'visible';
		
	}
  if(!blockchain.account){
    data.totalSupply = CONFIG.TEMP_TOTAL_SUPPLY;
  }
	
	if (data.ownerTokenCount.length >= CONFIG.WALLET_LIMIT )	{
		buyButtonVisibility = "hidden";
		
	}


  const claimNFTs = () => {
	  let i = 0;
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.SYMBOL}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
    .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `Congratulations, you bought ${mintAmount}: ${CONFIG.SYMBOL}. Scroll down to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 5) {
      newMintAmount = 5;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {

    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }	
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);


  return (
    <Router>
		<s.Screen>
		  <s.Container
			flex={1}
			ai={"center"}
			style={{ padding: 24, backgroundColor: "var(--primary)" }}
			image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
		  >
			<ResponsiveWrapper flex={1} style={{ padding: 24 }} test>

				<s.Container flex={1} jc={"center"} ai={"center"}>
				  <StyledImg alt={"example"} src={"/config/images/example.png"} />
				</s.Container>		  
				<StyledLogo alt={"logo"} src={"/config/images/fusefaceSmall.png"} />
				  <s.Container flex={1} jc={"center"} ai={"center"}>
				<StyledImg alt={"example"} src={"/config/images/example2.png"} />
				</s.Container>				
			</ResponsiveWrapper>
	  
	  
        <ResponsiveWrapper flex={1} style={{ padding: 14 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>	
			<s.Container jc={"center"} ai={"center"} style={{ width: "50%" }}>
		
			   <s.Container  flex={2}
				jc={"center"}
				ai={"center"}
				style={{
				  backgroundColor: "var(--accent)",
				  padding: 14,
				  borderRadius: 24,
				  border: "4px ridge var(--secondary)",
				  boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
				}}
				>
				<s.TextDescription
					style={{
					  textAlign: "center",
					  color: "var(--primary-text)",
					}}
				>
					FACE is an extremely rare NFT collectables token minted on the FUSE Blockchain. Released in 8 stages of 100 per stage.<br />
					These beautiful faces are not real people, they are artificially generated. <br />
					Any resemblance they may bear to any real person is coincidental and not intended.
				</s.TextDescription>
		       </s.Container>				
			</s.Container>
	  
		  </s.Container>
		</ResponsiveWrapper>

        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/example3.png"} />
          </s.Container>	  
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              border: "4px ridge var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
		  <s.TextTitle
			style={{ textAlign: "center", color: "var(--accent-text)", fontSize: 16, }}
          >
		  Your Account: {blockchain.account}
		  </s.TextTitle>
		  <s.SpacerSmall />
          <s.Container fd={"row"} flex={1} jc={"center"} ai={"center"}>
				
            <s.Container>
            <CoinImg alt={"example"} src={"/config/images/fuseCoinBright.png"} />
          </s.Container>
          <s.SpacerMedium />	  
          <s.Container >
            <CoinImg alt={"example"} src={"/config/images/lets3dCoin.png"} />
          </s.Container> 
          </s.Container>         
		  <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
		
			</s.TextTitle>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 40,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >             
              Sold this stage: {data.totalSupply} /  {CONFIG.STAGE_SUPPLY} <br />
              </s.TextTitle>
              <s.TextTitle
              style={{fontSize: 20, color: "var(--accent-text)",}}
            >
			Once this stage has sold out there will be a pause of a few hours before the next stage is released.<br />
Connect your wallet to update exact sales numbers.
</s.TextTitle>
<s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 40,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >   

			  Total project max: {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >              
              <StyledLink target={"_blank"} href="https://explorer.fuse.io/address/0x662DD934e2A2e44068FfBA2568B7a9a4709543d7/transactions">
                Contract: {CONFIG.CONTRACT_ADDRESS}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Excluding gas fees.
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} MainNet
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButtonConnect
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButtonConnect>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (         
				<>
		         <div 
				     style={{ visibility: buyButtonVisibility }}
					>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButtonBuy
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButtonBuy>
                    </s.Container>
					</div>
                  </>
                )}
              </>
            )}
			 <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >			 
			Wallet limit is {CONFIG.WALLET_LIMIT} FACEs per user.
			<s.SpacerSmall />
			A discussion about this project in on Telegram <StyledLink target={"_blank"} href="https://t.me/+0O9pXchwD08yY2I1">
			https://t.me/+0O9pXchwD08yY2I1
			</StyledLink>
			<s.SpacerSmall />
			Resell and trade FuseFace NFTs now available on  <StyledLink target={"_blank"} href="https://artrific.io/collection/0x662DD934e2A2e44068FfBA2568B7a9a4709543d7">
			Artrific Marketplace
			</StyledLink>			
			</s.TextDescription>
            <s.SpacerMedium />
			
			
			
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/example1.png"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
		  
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription>
        </s.Container>
		<s.SpacerMedium />

			<s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 12,
              borderRadius: 24,
              border: "4px ridge var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
			  width: 500,
			  visibility: theVisibility,
            }}
          >
            <s.TextDescription
              style={{
                textAlign: "center",
                fontSize: 30,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >   
				
			Your {data.ownerTokenCount.length} Ai Faces
			</s.TextDescription>
			</s.Container>
		 
			<s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
            </s.TextTitle>

			<s.Container jc={"space-between"} fd={"row"} style={{ flexWrap: "wrap"}}>
           
				{data.ownerTokenCount.map(item => {
					
				if (item >= 100 && item <= 200){
					 myUrl =  myUrl2;
				  }	else if (item > 200 && item <= 300){
						myUrl =  myUrl3;
				  }	else if (item > 300 && item <= 400){
						myUrl =  myUrl4;
				  }	else if (item > 400 && item <= 500){
						myUrl =  myUrl5;
				  }			  
                return (
				<s.Container key ={item}>
				
                  <s.TextTitle  fd={"row"}
                    style={{
                      textAlign: "center",
                      fontSize: 50,
                      fontWeight: "bold",
                      color: "var(--accent-text)",
                    }}
                  >
				  id: {item}
                  </s.TextTitle> 

					< StyledImgNew className="ui segments" src={myUrl + item + myTail} width="512px" alt="AI Face"/>
				 </s.Container>	 
                )
            })}
			
			</s.Container>		
			<About />
		</s.Container>
    </s.Screen>
    </Router>
  );
}

export default Home;
