import * as s from "./styles/globalStyles";
import styled from "styled-components";

export const CoinImg = styled.img`
  box-shadow: 1px 1px 5px 5px rgba(200, 200, 200, 0.7);

  background-color: var(--accent);
  border-radius: 100%;
  width: 128px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 180px;
  }
  transition: width 0.5s;
`;

const About = () => {
    return (      
		<div className="" >
			<s.Container flex={1} jc={"center"} ai={"center"}>

	            <div
					style={{
						textAlign: "left",
						fontSize: 16,
						width: "70%",
						backgroundColor: '#999',
						padding: 20,
						borderRadius: 24,
						border: "4px ridge var(--secondary)",
						boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
					}}
				> 
		
	            <s.TextDescription
					style={{
						textAlign: "center",
						fontSize: 24,
					}}
				> 
					About Face
				</s.TextDescription>
				<s.SpacerLarge />				
			<p>FACE is a collection of extremely rare Non Fungible Tokens depicting remarkably beautiful AI generated faces.<br />
			These images are not of real people, but are creations inspired by features and faces of people we all find attractive.</p>
			
			<s.SpacerSmall />
			
			<p>The contract is deployed on the Fuse Network and available originally from this minting Dapp at https://fusefaces.com. <br />
			
			Resell FACE NFTs will be available on the Fuse Marketplace at open market prices, once the marketplace is operational.</p>
			
			<s.SpacerSmall />
			
			Each one is quite unique, some extremely different and some may seem somewhat similar to some others<br /> 
			but like snowflakes or fingerprints each one is totally individual.<br />
			
			<s.SpacerSmall />
			
			<s.TextDescription style={{ fontSize: 24, }} > 
			There are only 800 to be minted 
			</s.TextDescription>
			
			so these treasures are expected to become quite valuable and certainly very collectable, get in early as they will go fast.<br />
			
			<s.SpacerSmall />
			
			<s.TextDescription style={{ fontSize: 24, }} > 
				
			They will be released in stages of 100 at a time, <br />
			with a minting limit of 5 at a time,<br /> 
			and a maximum wallet limit of 20 tokens, <br />
			</s.TextDescription>
			to allow the community to get a fair share.<br />
			<s.SpacerSmall />
			<s.TextDescription style={{ fontSize: 24, }} > 
			The original tokens of "Stage 1" are released at a price of 50Fuse, <br />
			</s.TextDescription>
			
			The FUSE price at the time of deployment is roughly 40c per fuse<br />
			this makes the tokens cost around $20 usd at initial release.<br /><br />
			The following stages will be more expensive at release, prices to be determined according to demand.<br />
			
			Of course we think this represents an incredible ground floor opportunity to be part of the new and growing NFT collectibles and investment community on the Fuse Network.
			
			<s.SpacerSmall />
			
			The "FACE" contract and Front-end  code were deployed by Lets3d.com<br />
			(VR, AR, Metaverse and Crypto Developers) as an entry into the NFT space. <br />
			Lets3d intend to expand into NFT games and develop fun and exciting projects.<br /><br />

			<s.TextDescription style={{ fontSize: 14, textAlign: "right"}} > 
			Special thanks to Daniel Botha.
			</s.TextDescription>

			<s.TextDescription style={{ fontSize: 10, textAlign: "center"}} > 
			All of the above details are subject to change without notice at the discretion of the owner of the project. 
			CryptoAssets are in early stage development and any investment carries risks. <br />
			The NFTs associated with this project are released as novelties and no return value is guaranteed.
			No responsibility will be accepted for any losses whatsoever.<br />
			&copy; Copywrite 2021 Lets3d
			</s.TextDescription>
			</div>	
			</s.Container>
        </div>
     );
}
 
export default About;
