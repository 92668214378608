import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import GetImage from "./GetImage";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import About from './About';
import Home from './Home';

function App(){

	return (
		<Router>
			<div className="App">
				<Switch>

					<Route exact path="/">
						<Home/>
					</Route>
	  
					<Route path="/About">
						<About/>
					</Route>
  
				</Switch>
			</div>
		</Router>
	);
}

export default App;
