import React, { Component } from "react";
import styled from "styled-components";
//import { Segment, Button, Form } from 'semantic-ui-react';

export const StyledImgNew = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 8px ridge var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 512px;
  @media (min-width: 900px) {
    width: 512px;
  }
  @media (min-width: 1000px) {
    width: 512px;
  }
  transition: width 0.5s;
`;

class GetImage extends Component {
	constructor(props) {
		super(props)
		this.state = {
		}
	}
	
	async componentDidMount(){
		let url = "https://gateway.pinata.cloud/ipfs/QmZCehGBY1VgPGJfUfYDDxEWkxvckQkbdnnbLY73HrEizL/";
		let tail = ".png";
		let ourRandom = Math.floor(Math.random() * (600 - 2)) ;
		let ourString = url + ourRandom + tail;
		this.setState({
			image: ourString,
		})
	}

	render() {
		return (
			<div className="ui horizontal segments">
				<div className="ui segments">	
					< StyledImgNew className="ui segments" src={this.state.image} width="512px" alt="AI Face"/>
				</div>	
			</div>
		)
	}
}

export default GetImage;